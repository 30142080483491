import React from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';

const CookieWrapper = styled.span`
.CookieConsent {
    background-color: transparent !important;
    padding: 2rem !important;

    ::before {
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;  
    opacity: 1; 
    z-index: -1;
    background-color: ${({ theme }) => theme.color.grey1} !important;
}

    p {
        color: white;
        font-size: 16px;
    }

    button {
      background-color: ${({ theme }) => theme.color.white} !important;
      color: ${({ theme }) => theme.color.font} !important;
      font-weight: 700;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      padding-bottom: .6rem !important;
      padding-top: .6rem !important;
      margin-left: 2rem !important;
      transition: all .5s;
      cursor: pointer;
      position: relative;

      ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; 
        height: 100%;
        background-color: transparent;
        z-index: -1;
        transform: translate(7px, 7px);
        border: 2px solid white;
        transition: all .5s;
      }
    
      :hover{
        color: ${({ theme }) => theme.color.grey3} !important;
        background-color: ${({ theme }) => theme.color.grey7} !important;

            ::before {
            background-color: ${({ theme }) => theme.color.grey3};
            border: 2px solid ${({ theme }) => theme.color.grey3};
            }
        }
    }
}
`;

const Cookie = () => {

    return (
        <CookieWrapper>
            <CookieConsent
                location="bottom"
                buttonText="Akceptuję"
                declineButtonText="Decline"
                cookieName="gatsby-gdpr-google-analytics">
                <p><b>INFORMACJA DOTYCZĄCA PLIKÓW COOKIES</b></p>
                <p>Informujemy, iż w celu optymalizacji treści dostępnych w naszym serwisie, dostosowania ich do Państwa indywidualnych potrzeb korzystamy z informacji zapisanych za pomocą plików cookies na urządzeniach końcowych użytkowników. Pliki cookies użytkownik może kontrolować za pomocą ustawień swojej przeglądarki internetowej. Dalsze korzystanie z naszego serwisu internetowego, bez zmiany ustawień przeglądarki internetowej oznacza, iż użytkownik akceptuje stosowanie plików cookies.</p>
            </CookieConsent>
        </CookieWrapper>
    )
}
export default Cookie

