import styled, { css } from 'styled-components'
import { up, down } from 'styled-breakpoints'


const baseHeadings = css`
  color: ${props => props.colorFont || props.theme.color.heading};
  font-weight: ${props => props.weight || props.theme.font.weightH};
  font-family: ${props => props.fontFamily || props.theme.font.header};
  text-transform: ${props => props.textTransform};
  text-align: ${props => props.textAlign};
  padding-bottom: ${props => props.pb};
  padding-top: ${props => props.pt};
  padding-left: ${props => props.pl};
  padding-right: ${props => props.pr};
  margin-bottom: ${props => props.mb};
  margin-top: ${props => props.mt};
  margin-left: ${props => props.ml};
  margin-right: ${props => props.mr};
`;

export const Text = styled.p`
  color: ${props => props.colorFont || props.theme.color.font};
  font-family: ${props => props.fontFamily || props.theme.font.main};
  text-transform: ${props => props.textTransform};
  font-weight: ${props => props.weight || props.theme.font.weight};
  font-size: ${props => props.xl || props.theme.font.size.p.xl};
  line-height: ${props => props.lineHeight || props.theme.font.lineH.p.xl};
  text-align: ${props => props.textAlign};
  padding-bottom: ${props => props.pb};
  padding-top: ${props => props.pt};
  padding-left: ${props => props.pl};
  padding-right: ${props => props.pr};
  margin-bottom: ${props => props.mb};
  margin-top: ${props => props.mt};
  margin-left: ${props => props.ml};
  margin-right: ${props => props.mr};
  white-space: ${props => props.whiteSpace || 'normal'};

  ${down('lg')} {
    font-size: ${props => props.l || props.theme.font.size.p.l};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.p.l};
  }

  ${down('md')} {
    font-size: ${props => props.m || props.theme.font.size.p.m};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.p.m};
  }

  ${down('sm')} {
    font-size: ${props => props.s || props.theme.font.size.p.s};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.p.s};
  }

  ${down('xs')} {
    font-size: ${props => props.xs || props.theme.font.size.p.xs};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.p.xs};
  }
`;

export const H6 = styled.h6`
  ${baseHeadings}
  font-size: ${props => props.xl || props.theme.font.size.h6.xl};
  line-height: ${props => props.lineHeight || props.theme.font.lineH.h6.xl};
  
  ${down('lg')} {
    font-size: ${props => props.l || props.theme.font.size.h6.l};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h6.l};
  }

  ${down('md')} {
    font-size: ${props => props.m || props.theme.font.size.h6.m};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h6.m};
  }

  ${down('sm')} {
    font-size: ${props => props.s || props.theme.font.size.h6.s};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h6.s};
  }

  ${down('xs')} {
    font-size: ${props => props.xs || props.theme.font.size.h6.xs};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h6.xs};
  }
`;

export const H5 = styled.h5`
  ${baseHeadings}
  font-size: ${props => props.xl || props.theme.font.size.h5.xl};
  line-height: ${props => props.lineHeight || props.theme.font.lineH.h5.xl};
  
  ${down('lg')} {
    font-size: ${props => props.l || props.theme.font.size.h5.l};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h5.l};
  }

  ${down('md')} {
    font-size: ${props => props.m || props.theme.font.size.h5.m};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h5.m};
  }

  ${down('sm')} {
    font-size: ${props => props.s || props.theme.font.size.h5.s};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h5.s};
  }

  ${down('xs')} {
    font-size: ${props => props.xs || props.theme.font.size.h5.xs};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h5.xs};
  }
`;

export const H4 = styled.h4`
  ${baseHeadings}
  font-size: ${props => props.xl || props.theme.font.size.h4.xl};
  line-height: ${props => props.lineHeight || props.theme.font.lineH.h4.xl};

  ${down('lg')} {
    font-size: ${props => props.l || props.theme.font.size.h4.l};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h4.l};
  }

  ${down('md')} {
    font-size: ${props => props.m || props.theme.font.size.h4.m};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h4.m};
  }

  ${down('sm')} {
    font-size: ${props => props.s || props.theme.font.size.h4.s};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h4.s};
  }

  ${down('xs')} {
    font-size: ${props => props.xs || props.theme.font.size.h4.xs};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h4.xs};
  }
`;

export const H3 = styled.h3`
  ${baseHeadings}
  font-size: ${props => props.xl || props.theme.font.size.h3.xl};
  line-height: ${props => props.lineHeight || props.theme.font.lineH.h3.xl};

  ${down('lg')} {
    font-size: ${props => props.l || props.theme.font.size.h3.l};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h3.l};
  }

  ${down('md')} {
    font-size: ${props => props.m || props.theme.font.size.h3.m};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h3.m};
  }

  ${down('sm')} {
    font-size: ${props => props.s || props.theme.font.size.h3.s};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h3.s};
  }

  ${down('xs')} {
    font-size: ${props => props.xs || props.theme.font.size.h3.xs};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h3.xs};
  }
`;

export const H2 = styled.h2`
  ${baseHeadings}
  font-size: ${props => props.xl || props.theme.font.size.h2.xl};
  line-height: ${props => props.lineHeight || props.theme.font.lineH.h2.xl};

  ${down('lg')} {
    font-size: ${props => props.l || props.theme.font.size.h2.l};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h2.l};
  }

  ${down('md')} {
    font-size: ${props => props.m || props.theme.font.size.h2.m};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h2.m};
  }

  ${down('sm')} {
    font-size: ${props => props.s || props.theme.font.size.h2.s};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h2.s};
  }

  ${down('xs')} {
    font-size: ${props => props.xs || props.theme.font.size.h2.xs};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h2.xs};
  }
`;

export const H1 = styled.h1`
  ${baseHeadings}
  font-size: ${props => props.xl || props.theme.font.size.h1.xl};
  line-height: ${props => props.lineHeight || props.theme.font.lineH.h1.xl};

  ${down('lg')} {
    font-size: ${props => props.l || props.theme.font.size.h1.l};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h1.l};
  }

  ${down('md')} {
    font-size: ${props => props.m || props.theme.font.size.h1.m};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h1.m};
  }

  ${down('sm')} {
    font-size: ${props => props.s || props.theme.font.size.h1.s};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h1.s};
  }

  ${down('xs')} {
    font-size: ${props => props.xs || props.theme.font.size.h1.xs};
    line-height: ${props => props.lineHeight || props.theme.font.lineH.h1.xs};
  }
`;

export const TextHtml = styled.div`
  
  && p {
    color: ${props => props.colorFont || props.theme.color.font};
    font-family: ${props => props.fontFamily || props.theme.font.main};

    ${up('xl')} {
        font-size: ${props => props.xl || props.theme.font.size.p.xl};
        line-height: ${props => props.lineHeight || 1.7};
    }

    ${down('lg')} {
        font-size: ${props => props.l || props.theme.font.size.p.l};
        line-height: ${props => props.lineHeight || 1.6};
    }

    ${down('md')} {
        font-size: ${props => props.m || props.theme.font.size.p.m};
        line-height: ${props => props.lineHeight || 1.5};
    }

    ${down('sm')} {
        font-size: ${props => props.s || props.theme.font.size.p.s};
        line-height: ${props => props.lineHeight || '1,5'};
    }

    ${down('xs')} {
        font-size: ${props => props.xs || props.theme.font.size.p.xs};
        line-height: ${props => props.lineHeight || '1,5'};
    }
  }
`;

export const Label = styled.p`
  color: ${props => props.colorFont || props.theme.color.base};
  font-family: ${props => props.fontFamily || props.theme.font.main};
  text-transform: ${props => props.textTransform || 'uppercase'};
  font-weight: ${props => props.weight || props.theme.font.weightB};

  ${up('xl')} {
    font-size: ${props => props.xl || props.theme.font.size.l.xl};
    line-height: ${props => props.lineHeight || 1.7};
  }

  ${down('lg')} {
    font-size: ${props => props.l || props.theme.font.size.l.l};
    line-height: ${props => props.lineHeight || 1.6};
  }

  ${down('md')} {
    font-size: ${props => props.m || props.theme.font.size.l.m};
    line-height: ${props => props.lineHeight || 1.5};
  }

  ${down('sm')} {
    font-size: ${props => props.s || props.theme.font.size.l.s};
    line-height: ${props => props.lineHeight || '1,5'};
  }

  ${down('xs')} {
    font-size: ${props => props.xs || props.theme.font.size.l.xs};
    line-height: ${props => props.lineHeight || '1,5'};
  }
`;

export const LabelBW = styled(Text)`
  color: ${props => props.theme.color.grey6};
  font-family: ${props => props.fontFamily || props.theme.font.main};
  text-transform: ${props => props.textTransform || 'uppercase'};
  font-weight: 600;
  letter-spacing: 0.4rem;
  font-size: ${props => props.l || props.theme.font.size.l.xl};
  line-height: 1.6;

  ${down('lg')} {
    font-size: ${props => props.l || props.theme.font.size.l.l};
    line-height: ${props => props.lineHeight || 1.6};
  }

  ${down('md')} {
    font-size: ${props => props.m || props.theme.font.size.l.m};
    line-height: ${props => props.lineHeight || 1.5};
  }

  ${down('sm')} {
    font-size: ${props => props.s || props.theme.font.size.l.s};
    line-height: ${props => props.lineHeight || '1,5'};
  }

  ${down('xs')} {
    font-size: ${props => props.xs || props.theme.font.size.l.xs};
    line-height: ${props => props.lineHeight || '1,5'};
  }
`;