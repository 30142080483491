import React from 'react';
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const Logo = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: ${(props) => props.position || 'absolute'};
    height: 90px;
    z-index: 150;
    > div {
        margin: 1rem;
        height: 100%;
    }

    ${up("md")} {
      display: none;
    }

`;

const LogoMobile = (props) => {
    return (
        <Logo to='/'
            position={props.position}>
            <StaticImage
                src='../../../assets/images/logo/logo.png'
                alt='Logo='
                placeholder="blurred"
                layout="fixed"
                height={38} />
        </Logo>
    )
}
export default LogoMobile