export const godziny = [
  {
    id: 1,
    day: "PN",
    godzinaOd: "7:00",
    godzinaDo: "17:00",
  },
  {
    id: 2,
    day: "WT",
    godzinaOd: "7:00",
    godzinaDo: "17:00",
  },
  {
    id: 3,
    day: "ŚR",
    godzinaOd: "7:00",
    godzinaDo: "17:00",
  },
  {
    id: 4,
    day: "CZ",
    godzinaOd: "7:00",
    godzinaDo: "17:00",
  },
  {
    id: 5,
    day: "PT",
    godzinaOd: "7:00",
    godzinaDo: "17:00",
  },
  {
    id: 6,
    day: "SB",
    godzinaOd: "",
    godzinaDo: "",
  },
  {
    id: 7,
    day: "ND",
    godzinaOd: "",
    godzinaDo: "",
  },
]

export const info = {
  wydzial: "Wydział Architektury Pol. Śl.",
  ulica: "Akademicka",
  nrBud: "7 (hol główny)",
  kod: "44-100",
  miasto: "Gliwice",
  woj: "Śląskie",
  kraj: "Polska",
  tel: "732 429 032",
  mail: "iks.druk@gmail.com",
  nip: "646 28 33 128",
  regon: "365516933",
}
