import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

const Icon = styled.button`
  position: fixed;
  right: 1rem;
  top: 1rem;
  display: block;

  height: 56px;
  width: 56px;

  font-size: 30px;
  color: ${({ theme }) => theme.color.grey1};

  background-color: rgba(2, 2, 2, 0);
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 0.5rem 0.8rem;
  z-index: 200;
  cursor: pointer;

  :hover {
    background-color: rgba(2, 2, 2, 0);
    color: ${({ theme }) => theme.color.grey5};
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.color.grey1};
  }

  ${up("md")} {
    display: none;
  }
`

const HamburgerMenuIcon = props => {
  return (
    <Icon onClick={props.onClick}>
      <FontAwesomeIcon icon={props.state ? faTimes : faBars} />
    </Icon>
  )
}
export default HamburgerMenuIcon
